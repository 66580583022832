import { Input, message } from 'antd';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import s from './s.module.less';
import { updateOpsChannelWebsite } from 'api/operation';

interface IProps {
    channelId: number;
    providerId: number;
    website?: string;
    refetch: () => void;
}

const GoLiveCard = ({
    channelId,
    providerId,
    website = '',
    refetch,
}: IProps) => {
    const [edit, setEdit] = useState(false);
    const [value, setValue] = useState(website);
    const [messageApi, contextHolder] = message.useMessage();

    useEffect(() => {
        if (website) {
            setValue(website);
        }
    }, [website]);

    const handleChangeEditStatus = useCallback(() => {
        setEdit(!edit);
    }, [edit]);

    const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
    }, []);

    const handleSave = useCallback(async () => {
        const res = await updateOpsChannelWebsite({
            channelId,
            providerId,
            website: value,
        });

        if (!res?.error) {
            setEdit(false);
            refetch();
        } else {
            messageApi.error(res.error);
        }
    }, [channelId, messageApi, providerId, refetch, value]);

    const handleCancel = useCallback(() => {
        setValue(website);
        setEdit(false);
    }, [website]);

    return (
        <div className={s.wrap}>
            {contextHolder}
            <div className={s.title}>Have you added the provider&apos;s information to the Channel correctly?</div>
            <div className={s.websiteBox}>
                {
                    edit ? (
                        <>
                            <Input style={{ width: '312px' }} value={value} onChange={handleChange} />
                            <div className={s.saveBtn} onClick={handleSave}>Save</div>
                            <div className={s.cancelBtn} onClick={handleCancel}>Cancel</div>
                        </>
                    ) : (
                        <>
                            <div className={s.website}>{value}</div>
                            <div className={s.editIcon} onClick={handleChangeEditStatus} />
                        </>
                    )
                }
            </div>
        </div>
    );
};

export default GoLiveCard;
