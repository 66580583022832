import { Button, Input, Radio, RadioChangeEvent, Space, message } from 'antd';
import React, { ChangeEvent, useCallback, useState } from 'react';
import s from './s.module.less';
import { updateProviderChannelAuditStatus } from 'api/operation';

interface IProps {
    providerEmail?: string;
    providerId?: number;
    channelId: number;
    refetch: () => void;
}

const WaitApproveCard = ({
    providerEmail,
    providerId,
    channelId,
    refetch,
}: IProps) => {
    const [messageApi, contextHolder] = message.useMessage();
    const [radionValue, setRadioValue] = useState('0');
    const [inputValue, setInputValue] = useState('');

    const handleRadioChange = useCallback((e: RadioChangeEvent) => {
        setRadioValue(e.target.value);
    }, []);

    const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    }, []);

    const handleUpdate = useCallback(async (passed: boolean, website?: string) => {
        if (providerId) {
            const res = await updateProviderChannelAuditStatus({
                channelId,
                providerId,
                passed,
                website,
            });

            if (!res?.error) {
                refetch();
            } else {
                messageApi.error(res?.error);
            }
        }
    }, [channelId, messageApi, providerId, refetch]);

    const handleReject = useCallback(async () => {
        handleUpdate(false);
        window.open(`mailto:${providerEmail}`);
    }, [handleUpdate, providerEmail]);

    const handleGoLive = useCallback(async () => {
        handleUpdate(true, inputValue);
    }, [handleUpdate, inputValue]);

    return (
        <div className={s.wrap}>
            {contextHolder}
            <div className={s.title}>Has the provider's application been approved by the channel?</div>
            <div className={s.selectBox}>
                <Radio.Group value={radionValue} onChange={handleRadioChange}>
                    <Space direction="vertical">
                        <Radio value="0">No, need to contact provider for updating UniProfile.</Radio>
                        <Radio value="1">Yes, here is the profile link for the channel.</Radio>
                    </Space>
                </Radio.Group>
                {
                    radionValue === '1' &&
                    <div style={{ marginTop: '8px' }}>
                        <Input style={{ width: '300px' }} value={inputValue} placeholder="www.example.com" onChange={handleInputChange} />
                    </div>
                }
            </div>
            {
                radionValue === '0' ?
                    <Button type="primary" ghost onClick={handleReject}>Contact provider</Button> :
                    <Button type="primary" ghost onClick={handleGoLive}>Update status to Listing go-live</Button>
            }
        </div>
    );
};

export default WaitApproveCard;
