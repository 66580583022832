import React, { useEffect, useState } from 'react';
import { Button, Descriptions, Spin } from 'antd';
import { TPageManagement, type TConditionSetting, type TObjectAction } from 'types/helloKlarity';
import EditCondition from '../EditCondition';
// import useKlarityCondition from 'hooks/useKlarityCondition';

import s from './s.module.less';

type IProps = {
    isLoading: boolean;
    goLiveObj: TPageManagement;
    onSuccess: (obj: TObjectAction) => void;
    onPreview: (list: TConditionSetting[]) => void;
};

const ConditionManagement: React.FC<IProps> = ({
    goLiveObj,
    isLoading,
    onPreview,
    onSuccess,
}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [item, setItem] = useState<TConditionSetting>();
    const [condition, setCondition] = useState<TConditionSetting[]>([]);
    // const { condition, isLoading, fetchData } = useKlarityCondition();

    const handleEdit = (selectItem: TConditionSetting) => {
        setItem(selectItem);
        setIsOpen(true);
    };

    const handleSuccess = (obj: TObjectAction) => {
        setIsOpen(false);
        setItem(undefined);
        onSuccess(obj);
    // fetchData();
    };

    useEffect(() => {
        onPreview(condition);
    }, [condition]);

    useEffect(() => {
        setCondition(goLiveObj.conditions);
    }, [goLiveObj]);

    return (
        <div className={s.wrap}>
            <Spin spinning={isLoading}>
                <div className={s.wrapper}>
                    {condition?.map((conditionItem: TConditionSetting, index) => (
                        <div className={s.panel} key={index}>
                            <Descriptions
                                title={`Condition ${conditionItem.priority}`}
                                layout="vertical"
                                extra={
                                    <Button onClick={() => handleEdit(conditionItem)}>
                                        Edit
                                    </Button>
                                }
                                column={1}
                                size="small"
                                contentStyle={{
                                    marginBottom: '16px',
                                }}
                            >
                                <Descriptions.Item label="Condition name">
                                    {conditionItem.conditionName}
                                </Descriptions.Item>
                                <Descriptions.Item label="Subtext">
                                    {conditionItem.subtext}
                                </Descriptions.Item>
                                <Descriptions.Item label="Logo">
                                    {conditionItem.logo && (
                                        <img
                                            src={conditionItem.logo}
                                            alt={conditionItem.conditionName}
                                            className={s.conditionLogo}
                                        />
                                    )}
                                </Descriptions.Item>
                            </Descriptions>
                        </div>
                    ))}
                </div>
            </Spin>
            {isOpen && (
                <EditCondition
                    item={item}
                    list={condition}
                    onCancel={() => setIsOpen(false)}
                    onSuccess={handleSuccess}
                />
            )}
        </div>
    );
};

export default ConditionManagement;
