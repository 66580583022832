import React from 'react';
import s from './s.module.less';
import { TConditionSetting } from 'types/helloKlarity';

type IProps = {
    isMobileMode: boolean;
    previewList: TConditionSetting[];
};

const ConditionPreview = (props: IProps) => {
    const { isMobileMode, previewList } = props;
    return (
        <div className={isMobileMode ? s.previewMobile : s.previewPc}>
            <div className={s.content}>
                <div className={s.title}>
                    Top providers by most-searched specialties
                </div>
                <div className={s.providerList}>
                    {previewList?.map((item) => (
                        <div className={s.providerColumn} key={item.conditionName}>
                            <div className={s.info}>
                                <div className={s.infoHeader}>
                                    <div className={s.logoBox}>
                                        <img src={item.logo} className={s.logo} alt="logo" />
                                    </div>
                                    <div className={s.name}>{item.conditionName}</div>
                                </div>
                                <div className={s.description}>{item.subtext}</div>
                                <div className={s.link}>View all Providers</div>
                            </div>
                            <div className={s.cardWrap}>
                                <div className={s.card} />
                                <div className={s.card} />
                                <div className={s.card} />
                                <div className={s.shadow} />
                                <div className={s.arrow} />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ConditionPreview;
