import React, { useMemo, useState } from 'react';
import s from './s.module.less';
import { Tabs } from 'antd';
import Specialties from './components/Specialties';
import ServiceType from './components/ServiceType';

const KlarityServices = () => {
    const [reloadSpecialtiesTable, setReloadSpecialtiesTable] = useState<object>();
    const items = useMemo(() => {
        return [
            {
                key: '1',
                label: 'Specialties',
                children: (
                    <div className={s.tabContent}>
                        <Specialties reloadSpecialtiesTable={reloadSpecialtiesTable} />
                    </div>
                ),
            },
            {
                key: '2',
                label: 'Service type',
                children: (
                    <div className={s.tabContent}>
                        <ServiceType setReloadSpecialtiesTable={setReloadSpecialtiesTable} />
                    </div>
                ),
            },
        ];
    }, [reloadSpecialtiesTable]);
    return (
        <div className={s.wrap}>
            <Tabs
                type="card"
                items={items}
            />
        </div>
    );
};

export default KlarityServices;
