import React, { useCallback, useEffect, useMemo, useState } from 'react';
import s from './s.module.less';
import CommonTable from 'components/CommonTable';
import type { ColumnsType } from 'antd/es/table';
import useGetServiceTypeList from 'hooks/klarityServiceTemplateOps/useGetServiceTypeList';
import { Button, Modal, message } from 'antd';
import { EMandatoryOptions, IKlarityServiceType } from 'types/serviceTemplate';
import { EContactType, IKlarityServiceTypeLimitation, IKlarityServiceTypePatientGroup } from 'types/common';
import commonS from 'styles/common.module.less';
import EditForm from './components/EditForm';

interface IProps {
    setReloadSpecialtiesTable: (fn: object) => void;
}

const ServiceType = ({
    setReloadSpecialtiesTable,
}: IProps) => {
    // const [loading, setLoading] = useState(true);
    const [showEditModal, setShowEditModal] = useState(false);
    const [tableData, tableLoading, error, refetch] = useGetServiceTypeList();
    const [editRow, setEditRow] = useState<IKlarityServiceType>();
    const [saveLoading, setSaveLoading] = useState(false);

    useEffect(() => {
        if (error) {
            message.error(error);
        }
    }, [error]);

    const saveSuccessfully = useCallback(async () => {
        setShowEditModal(false);
        refetch();
        setReloadSpecialtiesTable({});
    }, [refetch, setReloadSpecialtiesTable]);

    const columns: ColumnsType<IKlarityServiceType> = useMemo(() => (
        [
            {
                title: 'TYPE',
                dataIndex: 'serviceType',
                width: '260px',
                fixed: 'left',
                className: s.tableColumn,
            },
            {
                title: 'DISPLAY NAME',
                dataIndex: 'displayName',
                width: '260px',
                className: s.tableColumn,
            },
            {
                title: 'DESCRIPTION',
                dataIndex: 'description',
                width: '300px',
                className: s.tableColumn,
                render: (text:string) => {
                    return (
                        <div className={s.nameWrap} title={text}>
                            {text}
                        </div>
                    );
                },
            },
            {
                title: 'MANDATORY',
                dataIndex: 'mandatory',
                className: s.tableColumn,
                width: '150px',
                render: (data: boolean) => (data ? EMandatoryOptions.MANDATORY : EMandatoryOptions.OPTIONAL),
            },
            {
                title: 'CONTACT TYPE',
                // dataIndex: 'contactType',
                width: '200px',
                className: s.tableColumn,
                render: (e, data:IKlarityServiceType) => {
                    const { teleHealth, inPerson } = data;

                    const contactTypes = [];

                    if (inPerson) {
                        contactTypes.push(EContactType.IN_PERSON);
                    }

                    if (teleHealth) {
                        contactTypes.push(EContactType.TELE_HEALTH);
                    }

                    return contactTypes.length > 0 ? (
                        <ul className={s.contactTypesUl}>
                            {
                                contactTypes.map((type: string, i: number) => {
                                    return <li key={i}>{type}</li>;
                                })
                            }
                        </ul>
                    ) : 'No meeting required';
                },
            },
            {
                title: 'DURATION',
                dataIndex: 'defaultDuration',
                width: '120px',
                className: s.tableColumn,
            },
            {
                title: 'NEW PATIENT',
                dataIndex: 'newPatient',
                width: '120px',
                className: s.tableColumn,
            },
            {
                title: 'PATIENT ACCEPTED',
                dataIndex: 'klarityServiceTypePatientGroupList',
                width: '150px',
                className: s.tableColumn,
                render: (v?: IKlarityServiceTypePatientGroup[]) => {
                    if (!v || v.length === 0) {
                        return 'No need for setup';
                    }

                    return `${v.filter((e) => e.id).length} groups`;
                },
            },
            {
                title: 'LIMITATION',
                dataIndex: 'klarityServiceTypeLimitationList',
                width: '150px',
                className: s.tableColumn,
                render: (v?: IKlarityServiceTypeLimitation[]) => {
                    if (!v || v.length === 0) {
                        return 'No limitations';
                    }

                    return `${v.filter((e) => e.id).length} limitations`;
                },
            },
            {
                title: '',
                width: '80px',
                className: s.tableColumn,
                fixed: 'right',
                render: (item: IKlarityServiceType) => {
                    return (
                        <div
                            className={s.edit}
                            onClick={() => {
                                setShowEditModal(true);
                                setEditRow(item);
                            }}
                        >Edit
                        </div>
                    );
                },
            },
        ]
    ), []);

    return (
        <div className={s.wrap}>
            <Modal
                title={`${editRow?.displayName}`}
                className={commonS.modalFixHeightWrap}
                width={900}
                destroyOnClose
                open={showEditModal}
                onCancel={() => setShowEditModal(false)}
                footer={
                    <div className={s.modalFooter}>
                        <Button onClick={() => setShowEditModal(false)}>Cancel</Button>
                        <Button type="primary" htmlType="submit" form="serviceTypeForm" loading={saveLoading}>Save</Button>
                    </div>
                }
            >
                <EditForm
                    row={editRow}
                    setSaveLoading={setSaveLoading}
                    onSaveSuccessfully={saveSuccessfully}
                />
            </Modal>
            <CommonTable
                rowKey={(record: IKlarityServiceType) => record.id}
                bordered
                loading={tableLoading}
                scroll={{ x: '1500' }}
                columns={columns}
                data={tableData}
            />
        </div>
    );
};

export default ServiceType;
