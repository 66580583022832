import { Channel, TUtmInfo } from 'types/common';
import { Provider } from 'types/provider';
import { TSubscriptionItem } from 'types/subscription';

const PREFIX = 'box_practice';

export enum EKey {
    USER_ID = 'user_id',
    PROVIDER = 'provider',
    GOOGLE_TOKEN = 'google_token',
    GOOGLE_TOKEN_EXPIRED_AT = 'google_token_expired_at',
    TIME_ZONE = 'time_zone',
    BOX_PRACTICE_TOKEN = 'box_practice_token',
    TERMS_FOR_EXPORT = 'terms_for_export',
    OPS_TOKEN = 'ops_token',
    HAS_ONCE_SUCCESS_LOGIN = 'has_once_success_login',
    PF_URL = 'pf_url',
    HAS_VIEW_PROSPECT_INFO = 'has_view_prospect_info',
    HAS_VIEW_APPOINTMENT_INFO = 'has_view_appointment_info',
    SUBSCRIPTION_CALLBACK_PATH = 'subscription_callback_path',
    SUBSCRIPTION_CALLBACK_SHOW_SUCCESS = 'subscription_callback_show_success',
    PROVIDER_HAS_SET_NOTIFY_EMAILS = 'provider_has_set_notify_emails',
    PENDING_SUBSCRIPTION_CHANNEL = 'pending_subscription_channel',
    PENDING_SUBSCRIPTION_PLAN = 'pending_subscription_plan',
    HAS_VIEW_LOCAL_SEO_INFO = 'has_view_local_seo_info',
    DISABLE_SURVEY_CAP_NOTICE = 'disable_survey_cap_notice',
    HAS_SHOW_SURVEY_TOOLTIPS = 'has_show_survey_tooltips',
    IS_EDITING_SURVEY = 'is_editing_surver',
    IS_EDITING_REVIEW = 'is_editing_review',
    REPUTATION_SETUP_STEP = 'reputation_setup_step',
    IMPROTED_NEW_PATIENTS = 'imported_new_patients',
    AVA_DOCUMENT_ID = 'ava_document_id',
    DISABLE_SHOW_DIRECT_LINK_IN_PROSPECT = 'disable_show_direct_link_in_prospect',
    HAS_DISABLE_VIEW_SERVICE_CHECK = 'has_disable_view_service_check',
    OPS_RECENT_SEARCH = 'recent_search',
    HAS_VIEW_KIWI_EHR_PAGE = 'has_view_kiwi_ehr_page',
    HAS_VIEW_KLARITY_REVIEW_PAGE = 'has_view_klarity_review_page',
    HAS_DONE_KLARITY_TUTORIAL = 'has_done_klarity_tutorial',
    HAS_SHOW_AVA_SETTING_IN_PROSEPECT = 'has_show_ava_setting_in_prosepect',
    UTM_INFO = 'utm_info',
    HIDE_CMD_FOREVER = 'hide_cmd_forever', // hide channel management delegation forever https://www.figma.com/design/rWhgWwp3dNeYzTPJMPoqVP/Channels?node-id=3350-39286&m=dev
    SHOW_KIWI_PLAN_ADVERTISE_TIMES = 'show_kiwi_plan_advertise_times',
}

export enum EDot {
    PROSPECT_0810 = 'prospect_0810',
}

export const getKey = (key: string): string => {
    return `${PREFIX}_${key}`;
};

const setProvider = (data?: Provider) => {
    const key = getKey(EKey.PROVIDER);
    if (!data) {
        localStorage.removeItem(key);
    }
    localStorage.setItem(key, JSON.stringify(data));
};

const getProvider = (): Provider | undefined => {
    const key = getKey(EKey.PROVIDER);
    const str = localStorage.getItem(key);
    if (!str) {
        return undefined;
    }
    return JSON.parse(str);
};

const setUserId = (id?: number) => {
    const key = getKey(EKey.USER_ID);
    if (!id) {
        localStorage.removeItem(key);
    }
    localStorage.setItem(key, `${id}`);
};

const getUserId = (): number | undefined => {
    const key = getKey(EKey.USER_ID);
    const str = localStorage.getItem(key);
    if (!str) {
        return undefined;
    }
    return parseInt(str, 10);
};

const setUserToken = (token?: string) => {
    const key = getKey(EKey.BOX_PRACTICE_TOKEN);
    if (!token) {
        localStorage.removeItem(key);
    }
    localStorage.setItem(key, `${token}`);
};

const setOpsToken = (token?: string) => {
    const key = getKey(EKey.OPS_TOKEN);
    if (!token) {
        localStorage.removeItem(key);
    }
    localStorage.setItem(key, `${token}`);
};

const getUserToken = (): string | undefined => {
    const key = getKey(EKey.BOX_PRACTICE_TOKEN);
    const str = localStorage.getItem(key);
    return str || undefined;
};

const setGoogleToken = (token?: string) => {
    const key = getKey(EKey.GOOGLE_TOKEN);
    if (!token) {
        localStorage.removeItem(key);
    }
    localStorage.setItem(key, token!);
};

const getGoogleToken = (): string | undefined => {
    const key = getKey(EKey.GOOGLE_TOKEN);
    return localStorage.getItem(key) || undefined;
};

const setGoogleTokenExpiredAt = (time?: number) => {
    const key = getKey(EKey.GOOGLE_TOKEN_EXPIRED_AT);
    if (!time) {
        localStorage.removeItem(key);
    }
    localStorage.setItem(key, `${time}`);
};

const getGoogleTokenExpiredAt = (): number | undefined => {
    const key = getKey(EKey.GOOGLE_TOKEN_EXPIRED_AT);
    const str = localStorage.getItem(key);
    if (!str) {
        return undefined;
    }
    return parseInt(str, 10);
};

const setTimeZone = (timeZone: string) => {
    const key = getKey(EKey.TIME_ZONE);
    if (!timeZone) {
        localStorage.removeItem(key);
    }
    localStorage.setItem(key, timeZone);
};

const getTimeZone = (): string | undefined => {
    const key = getKey(EKey.TIME_ZONE);
    const str = localStorage.getItem(key);
    if (!str) {
        return undefined;
    }
    return str;
};

const setHasReadTermsForExport = (val?: string) => {
    const key = getKey(EKey.TERMS_FOR_EXPORT);
    if (!val) {
        localStorage.removeItem(key);
    }
    localStorage.setItem(key, val!);
};

const getHasReadTermsForExport = (): boolean => {
    const key = getKey(EKey.TERMS_FOR_EXPORT);
    return !!localStorage.getItem(key);
};

const setHasOnceSuccessLogin = (val: string) => {
    const key = getKey(EKey.HAS_ONCE_SUCCESS_LOGIN);
    if (!val) {
        localStorage.removeItem(key);
    } else {
        localStorage.setItem(key, val!);
    }
};

const getHasOnceSuccessLogin = (): boolean => {
    const key = getKey(EKey.HAS_ONCE_SUCCESS_LOGIN);
    return !!localStorage.getItem(key);
};

const setPFUrl = (url?: string) => {
    const key = getKey(EKey.PF_URL);
    if (!url) {
        localStorage.removeItem(key);
    }
    localStorage.setItem(key, url!);
};

const getPFUrl = (): string | '' => {
    const key = getKey(EKey.PF_URL);
    return localStorage.getItem(key) || '';
};

const setHasViewProspect = (val?: string) => {
    const key = getKey(EKey.HAS_VIEW_PROSPECT_INFO);
    if (!val) {
        localStorage.removeItem(key);
    } else {
        localStorage.setItem(key, val!);
    }
};

const getHasViewProspect = (): boolean => {
    const key = getKey(EKey.HAS_VIEW_PROSPECT_INFO);
    return !!localStorage.getItem(key);
};

const setHasViewAppointment = (val?: string) => {
    const key = getKey(EKey.HAS_VIEW_APPOINTMENT_INFO);
    if (!val) {
        localStorage.removeItem(key);
    } else {
        localStorage.setItem(key, val!);
    }
};

const getHasViewAppointment = (): boolean => {
    const key = getKey(EKey.HAS_VIEW_APPOINTMENT_INFO);
    return !!localStorage.getItem(key);
};

const setSubscriptionCallbackShowSuccess = (val: boolean) => {
    const key = getKey(EKey.SUBSCRIPTION_CALLBACK_SHOW_SUCCESS);
    if (!val) {
        localStorage.removeItem(key);
    } else {
        localStorage.setItem(key, `${val}`);
    }
};

const getSubscriptionCallbackShowSuccess = (): boolean => {
    const key = getKey(EKey.SUBSCRIPTION_CALLBACK_SHOW_SUCCESS);
    return !!localStorage.getItem(key);
};

const setSubscriptionCallbackPath = (path: string) => {
    const key = getKey(EKey.SUBSCRIPTION_CALLBACK_PATH);
    if (!path) {
        localStorage.removeItem(key);
    } else {
        localStorage.setItem(key, `${path}`);
    }
};

const getSubscriptionCallbackPath = (): string | null => {
    const key = getKey(EKey.SUBSCRIPTION_CALLBACK_PATH);
    return localStorage.getItem(key);
};

const setHasSetNotifyEmails = (val?: string) => {
    const key = getKey(EKey.PROVIDER_HAS_SET_NOTIFY_EMAILS);
    if (!val) {
        localStorage.removeItem(key);
    }
    localStorage.setItem(key, val!);
};

const getHasSetNotifyEmails = (): boolean => {
    const key = getKey(EKey.PROVIDER_HAS_SET_NOTIFY_EMAILS);
    return !!localStorage.getItem(key);
};

const setPendingSubscriptionChannel = (channel?: Channel) => {
    const key = getKey(EKey.PENDING_SUBSCRIPTION_CHANNEL);
    if (!channel) {
        localStorage.removeItem(key);
    } else {
        try {
            localStorage.setItem(key, JSON.stringify(channel));
        } catch (e) {
            console.error(e);
        }
    }
};

const getPendingSubscriptionChannel = (): Channel | undefined => {
    const key = getKey(EKey.PENDING_SUBSCRIPTION_CHANNEL);
    const str = localStorage.getItem(key);
    try {
        if (str) {
            return JSON.parse(str) as Channel;
        }
    } catch (e) {
        console.error(e);
    }
    return undefined;
};

const setPendingSubscriptionPlan = <T>(plan?: T): void => {
    const key = getKey(EKey.PENDING_SUBSCRIPTION_PLAN);
    if (!plan) {
        localStorage.removeItem(key);
    } else {
        try {
            localStorage.setItem(key, JSON.stringify(plan));
        } catch (e) {
            console.error(e);
        }
    }
};

const getPendingSubscriptionPlan = (): TSubscriptionItem | undefined => {
    const key = getKey(EKey.PENDING_SUBSCRIPTION_PLAN);
    const str = localStorage.getItem(key);
    try {
        if (str) {
            return JSON.parse(str) as TSubscriptionItem;
        }
    } catch (e) {
        console.error(e);
    }
    return undefined;
};

const setHasViewLocalSeoInfo = (val?: string) => {
    const key = getKey(EKey.HAS_VIEW_LOCAL_SEO_INFO);
    if (!val) {
        localStorage.removeItem(key);
    } else {
        localStorage.setItem(key, val!);
    }
};

const getHasViewLocalSeoInfo = (): boolean => {
    const key = getKey(EKey.HAS_VIEW_LOCAL_SEO_INFO);
    return !!localStorage.getItem(key);
};

const getDisableSurveyCapNotice = (): boolean => {
    const key = getKey(EKey.DISABLE_SURVEY_CAP_NOTICE);
    return !!localStorage.getItem(key);
};

const setDisableSurveyCapNotice = (val?: string) => {
    const key = getKey(EKey.DISABLE_SURVEY_CAP_NOTICE);
    if (!val) {
        localStorage.removeItem(key);
    } else {
        localStorage.setItem(key, val!);
    }
};

const getHasShowSurveyTooltips = (): boolean => {
    const key = getKey(EKey.HAS_SHOW_SURVEY_TOOLTIPS);
    return !!localStorage.getItem(key);
};

const setHasShowSurveyTooltips = (val?: string) => {
    const key = getKey(EKey.HAS_SHOW_SURVEY_TOOLTIPS);
    if (!val) {
        localStorage.removeItem(key);
    } else {
        localStorage.setItem(key, val!);
    }
};

const setStartEditSurveyForm = (val?: string) => {
    const key = getKey(EKey.IS_EDITING_SURVEY);
    if (!val) {
        localStorage.removeItem(key);
    } else {
        localStorage.setItem(key, val!);
    }
};

const getIsStartEditSurveyForm = () => {
    const key = getKey(EKey.IS_EDITING_SURVEY);
    return localStorage.getItem(key) === '1';
};

const setStartEditReview = (val?: string) => {
    const key = getKey(EKey.IS_EDITING_REVIEW);
    if (!val) {
        localStorage.removeItem(key);
    } else {
        localStorage.setItem(key, val!);
    }
};

const getIsStartEditReview = () => {
    const key = getKey(EKey.IS_EDITING_REVIEW);
    return localStorage.getItem(key) === '1';
};

const setReputationSetupStep = (val?: string) => {
    const key = getKey(EKey.REPUTATION_SETUP_STEP);

    if (!val) {
        localStorage.removeItem(key);
    } else {
        localStorage.setItem(key, val!);
    }
};

const getReputationSetupStep = () => {
    const key = getKey(EKey.REPUTATION_SETUP_STEP);
    return localStorage.getItem(key);
};

const setImportedPatients = (val?: string) => {
    const key = getKey(EKey.IMPROTED_NEW_PATIENTS);

    if (!val) {
        sessionStorage.removeItem(key);
    } else {
        sessionStorage.setItem(key, val!);
    }
};

const getImportedPatients = () => {
    const key = getKey(EKey.IMPROTED_NEW_PATIENTS);
    return sessionStorage.getItem(key);
};

const setAvaDocumentId = (val?: string) => {
    const key = getKey(EKey.AVA_DOCUMENT_ID);

    if (!val) {
        localStorage.removeItem(key);
    } else {
        localStorage.setItem(key, val!);
    }
};

const getAvaDocumentId = () => {
    const key = getKey(EKey.AVA_DOCUMENT_ID);
    return localStorage.getItem(key);
};

const getDisableShowDirectLinkInProspect = (): boolean => {
    const key = getKey(EKey.DISABLE_SHOW_DIRECT_LINK_IN_PROSPECT);
    return !!localStorage.getItem(key);
};

const setDisableShowDirectLinkInProspect = (val?: string) => {
    const key = getKey(EKey.DISABLE_SHOW_DIRECT_LINK_IN_PROSPECT);
    if (!val) {
        localStorage.removeItem(key);
    } else {
        localStorage.setItem(key, val!);
    }
};

const getHasDisableViewServiceCheck = (): boolean => {
    const key = getKey(EKey.HAS_DISABLE_VIEW_SERVICE_CHECK);
    return !!localStorage.getItem(key);
};

const setHasDisableViewServiceCheck = (val?: string) => {
    const key = getKey(EKey.HAS_DISABLE_VIEW_SERVICE_CHECK);
    if (!val) {
        localStorage.removeItem(key);
    } else {
        localStorage.setItem(key, val!);
    }
};

const getOpsRecentSearch = (): string[] => {
    const key = getKey(EKey.OPS_RECENT_SEARCH);
    const val = localStorage.getItem(key);
    if (val) {
        try {
            return JSON.parse(val) as string[];
        } catch (e) {
            console.error(e);
        }
    }
    return [];
};

const addOpsRecentSearch = (add: string) => {
    const key = getKey(EKey.OPS_RECENT_SEARCH);
    const val = localStorage.getItem(key);
    if (val) {
        try {
            let valArr: string[] = JSON.parse(val) as string[];
            if (!valArr.includes(add)) {
                valArr.unshift(add);
            }
            valArr = valArr.slice(0, 3);
            localStorage.setItem(key, JSON.stringify(valArr));
            return valArr;
        } catch (e) {
            console.error(e);
        }
    } else {
        localStorage.setItem(key, JSON.stringify([add]));
    }
    return [];
};

const setHasViewKiwiEhrPage = (val?: 'true') => {
    const key = getKey(EKey.HAS_VIEW_KIWI_EHR_PAGE);
    if (!val) {
        localStorage.removeItem(key);
    }
    localStorage.setItem(key, val!);
};

const getHasViewKiwiEhrPage = (): boolean => {
    const key = getKey(EKey.HAS_VIEW_KIWI_EHR_PAGE);
    return !!localStorage.getItem(key);
};

const setHasViewKlarityReviewPage = (val?: 'true') => {
    const key = getKey(EKey.HAS_VIEW_KLARITY_REVIEW_PAGE);
    if (!val) {
        localStorage.removeItem(key);
    }
    localStorage.setItem(key, val!);
};

const getHasViewKlarityReviewPage = (): boolean => {
    const key = getKey(EKey.HAS_VIEW_KLARITY_REVIEW_PAGE);
    return !!localStorage.getItem(key);
};

const setHasDoneKlarityTutorial = (val?: 'true') => {
    const key = `${getKey(EKey.HAS_DONE_KLARITY_TUTORIAL)}_${getUserId()}`;
    if (!val) {
        localStorage.removeItem(key);
    }
    localStorage.setItem(key, val!);
};

const getHasDoneKlarityTutorial = (): boolean => {
    const key = `${getKey(EKey.HAS_DONE_KLARITY_TUTORIAL)}_${getUserId()}`;
    return !!localStorage.getItem(key);
};

const setHideChannelManagementDelegationForever = (val?: string) => {
    const key = getKey(EKey.HIDE_CMD_FOREVER);
    if (!val) {
        localStorage.removeItem(key);
    }
    localStorage.setItem(key, val!);
};

const getHideChannelManagementDelegationForever = (): boolean => {
    const key = getKey(EKey.HIDE_CMD_FOREVER);

    return !!localStorage.getItem(key);
};

const setShowAvaSettingInProsepect = (val?: 'true') => {
    const key = `${getKey(EKey.HAS_SHOW_AVA_SETTING_IN_PROSEPECT)}_${getUserId()}`;
    if (!val) {
        localStorage.removeItem(key);
    }
    localStorage.setItem(key, val!);
};

const getShowAvaSettingInProsepect = (): boolean => {
    const key = `${getKey(EKey.HAS_SHOW_AVA_SETTING_IN_PROSEPECT)}_${getUserId()}`;
    return !!localStorage.getItem(key);
};

const setHasViewDotPage = (target: EDot, val?: string) => {
    const key = `${getKey(`DOT_${target}`)}_${getUserId()}`;
    if (!val) {
        localStorage.removeItem(key);
    }
    localStorage.setItem(key, val!);
};

const getHasViewDotPage = (target: EDot): boolean => {
    const key = `${getKey(`DOT_${target}`)}_${getUserId()}`;
    return !!localStorage.getItem(key);
};

const getUtmInfo = (): TUtmInfo => {
    const key = getKey(EKey.UTM_INFO);
    const val = localStorage.getItem(key);
    if (val) {
        try {
            return JSON.parse(val);
        } catch (e) {
            return {};
        }
    }
    return {};
};

const setUtmInfo = (val?: TUtmInfo) => {
    const key = getKey(EKey.UTM_INFO);
    const current = getUtmInfo();
    const newVal = { ...current, ...(val || {}) };
    localStorage.setItem(key, JSON.stringify(newVal));
};

//clear after sign up
const clearUtmInfo = () => {
    const key = getKey(EKey.UTM_INFO);
    localStorage.removeItem(key);
};

const setShowKiwiPlanAdvertiseTimes = (val?: "1" | "2") => {
    const key = `${getKey(EKey.SHOW_KIWI_PLAN_ADVERTISE_TIMES)}_${getUserId()}`; getKey(EKey.SHOW_KIWI_PLAN_ADVERTISE_TIMES);
    if (!val) {
        localStorage.removeItem(key);
    } else {
        localStorage.setItem(key, val);
    }
};

const getShowKiwiPlanAdvertiseTimes = (): "1" | "2" | undefined => {
    const key = `${getKey(EKey.SHOW_KIWI_PLAN_ADVERTISE_TIMES)}_${getUserId()}`; getKey(EKey.SHOW_KIWI_PLAN_ADVERTISE_TIMES);
    const val = localStorage.getItem(key);
    if (!val) {
        return undefined
    }
    return localStorage.getItem(key) as "1" | "2";
}

export {
    setProvider,
    getProvider,
    setUserId,
    getUserId,
    setUserToken,
    getUserToken,
    setGoogleToken,
    getGoogleToken,
    setGoogleTokenExpiredAt,
    getGoogleTokenExpiredAt,
    setTimeZone,
    getTimeZone,
    setHasReadTermsForExport,
    getHasReadTermsForExport,
    setOpsToken,
    setHasOnceSuccessLogin,
    getHasOnceSuccessLogin,
    setPFUrl,
    getPFUrl,
    setHasViewProspect,
    getHasViewProspect,
    setHasViewAppointment,
    getHasViewAppointment,
    setSubscriptionCallbackShowSuccess,
    getSubscriptionCallbackShowSuccess,
    setSubscriptionCallbackPath,
    getSubscriptionCallbackPath,
    setHasSetNotifyEmails,
    getHasSetNotifyEmails,
    setPendingSubscriptionChannel,
    getPendingSubscriptionChannel,
    setPendingSubscriptionPlan,
    getPendingSubscriptionPlan,
    setHasViewLocalSeoInfo,
    getHasViewLocalSeoInfo,
    getDisableSurveyCapNotice,
    setDisableSurveyCapNotice,
    getHasShowSurveyTooltips,
    setHasShowSurveyTooltips,
    setStartEditSurveyForm,
    getIsStartEditSurveyForm,
    setStartEditReview,
    getIsStartEditReview,
    setReputationSetupStep,
    getReputationSetupStep,
    setImportedPatients,
    getImportedPatients,
    setAvaDocumentId,
    getAvaDocumentId,
    getDisableShowDirectLinkInProspect,
    setDisableShowDirectLinkInProspect,
    getHasDisableViewServiceCheck,
    setHasDisableViewServiceCheck,
    addOpsRecentSearch,
    getOpsRecentSearch,
    setHasViewKiwiEhrPage,
    getHasViewKiwiEhrPage,
    setHasViewKlarityReviewPage,
    getHasViewKlarityReviewPage,
    setHasDoneKlarityTutorial,
    getHasDoneKlarityTutorial,
    setHasViewDotPage,
    getHasViewDotPage,
    setShowAvaSettingInProsepect,
    getShowAvaSettingInProsepect,
    getUtmInfo,
    setUtmInfo,
    clearUtmInfo,
    setHideChannelManagementDelegationForever,
    getHideChannelManagementDelegationForever,
    setShowKiwiPlanAdvertiseTimes,
    getShowKiwiPlanAdvertiseTimes,
};
