import React, { useCallback, useEffect } from 'react';
import s from './s.module.less';
import { Checkbox, Form, Input, InputNumber, Radio, Switch, message } from 'antd';
import { IKlarityServiceType, EMandatoryOptions, ENewPatientAccept } from 'types/serviceTemplate';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import DragabelList from '../DragabelList';
import commonS from 'styles/common.module.less';
import { CONTACT_TYPES_OPTIONS } from 'constants/common';
import { updateKlarityServiceType } from 'api/operation';
import { EContactType } from 'types/common';

interface IProps {
    row?: IKlarityServiceType
    setSaveLoading: (v:boolean) => void;
    onSaveSuccessfully: () => void;
}

const EditForm = ({
    row,
    setSaveLoading,
    onSaveSuccessfully,
}: IProps) => {
    const [formInstance] = Form.useForm<IKlarityServiceType>();
    const showContactTypes = Form.useWatch('switchTeleHealthInPerson', formInstance);
    const showPatientGroupNLimitation = Form.useWatch('switchPatientGroupLimitation', formInstance);

    useEffect(() => {
        if (row) {
            formInstance.setFieldsValue(row);
        }
    }, [row, formInstance]);

    formInstance.getFieldValue('showContactTypes');

    const handleSumbit = useCallback(async (value) => {
        if (!row) {
            console.error(`row is ${row}`);
            return;
        }
        setSaveLoading(true);
        const { contactType } = value;
        const patientGroupList = value.klarityServiceTypePatientGroupList ? value.klarityServiceTypePatientGroupList : row.klarityServiceTypePatientGroupList;
        const limitationList = value.switchPatientGroupLimitation ? value.klarityServiceTypeLimitationList : row.klarityServiceTypeLimitationList;

        const params = {
            id: row.id,
            iconUri: row.iconUri,
            serviceType: value.serviceType,
            displayName: value.displayName,
            description: value.description,
            newPatient: !!(value.newPatient?.toLowerCase() === 'yes'),
            mandatory: value.isMandatory === EMandatoryOptions.MANDATORY,
            inPerson: !!(contactType?.includes(EContactType.IN_PERSON)),
            teleHealth: !!(contactType?.includes(EContactType.TELE_HEALTH)),
            defaultDuration: value.defaultDuration,
            minDuration: value.minDuration,
            maxDuration: value.maxDuration,
            switchPatientGroup: value.switchPatientGroup,
            switchLimitation: value.switchLimitation,
            switchPatientGroupLimitation: value.switchPatientGroupLimitation,
            switchTeleHealthInPerson: value.switchTeleHealthInPerson,
            qtyPatientGroup: value.klarityServiceTypePatientGroupList ? value.klarityServiceTypePatientGroupList.length : 0,
            qtyLimitation: value.klarityServiceTypeLimitationList ? value.klarityServiceTypeLimitationList.length : 0,
            patientGroupList: patientGroupList?.filter((e) => e.id || e.patientGroup),
            limitationList: limitationList?.filter((e) => e.id || e.limitation),
        };

        try {
            const res = await updateKlarityServiceType(params);

            if (res && !res.error) {
                onSaveSuccessfully?.();
            } else {
                message.error(res?.error);
            }
        } catch (e) {
            console.error(e);
        }
        setSaveLoading(false);
    }, [onSaveSuccessfully, row, setSaveLoading]);

    const handleFinishFailed = useCallback((errors) => {
        if (errors?.errorFields.length > 0) {
            message.error(errors.errorFields[0].errors?.[0]);
        }
    }, []);

    return (
        <div className={s.formWrap}>
            <div className={s.tip}><a className={s.required}>*</a> All fields are required</div>
            <Form
                id="serviceTypeForm"
                scrollToFirstError
                form={formInstance}
                className={commonS.formStyle1}
                layout="vertical"
                onFinish={handleSumbit}
                onFinishFailed={handleFinishFailed}
            >
                <div className={s.box}>
                    <div className={s.title}>Basic information</div>
                    <div className={s.formItem}>
                        <Form.Item
                            label="Display name"
                            name="displayName"
                            style={{ width: '370px' }}
                            rules={[
                                { required: true, message: 'Display name is a required field' },
                            ]}
                        >
                            <Input maxLength={128} />
                        </Form.Item>
                    </div>
                    <div className={s.formItem}>
                        <Form.Item
                            label="Description"
                            name="description"
                            rules={[
                                { required: true, message: 'Description is a required field' },
                            ]}
                        >
                            <Input.TextArea className={s.textarea} maxLength={350} showCount />
                        </Form.Item>
                    </div>
                    <div className={s.formItem}>
                        <Form.Item
                            label="Mandatory in practice template for specialties"
                            name="isMandatory"
                            rules={[
                                { required: true, message: 'Mandatory in practice template for specialties is a required field' },
                            ]}
                        >
                            <Radio.Group>
                                <Radio value={EMandatoryOptions.MANDATORY}>{EMandatoryOptions.MANDATORY}</Radio>
                                <Radio value={EMandatoryOptions.OPTIONAL}>{EMandatoryOptions.OPTIONAL}</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                    <div className={s.formItem}>
                        <Form.Item
                            label="New patient accepted"
                            name="newPatient"
                            rules={[
                                { required: true, message: 'New patient accepted is a required field' },
                            ]}
                        >
                            <Radio.Group>
                                <Radio value={ENewPatientAccept.YES}>{ENewPatientAccept.YES}</Radio>
                                <Radio value={ENewPatientAccept.NO}>{ENewPatientAccept.NO}</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                    <div className={s.formItem}>
                        <div className={s.mandatoryLabel}>Contact type <a className={s.required}>*</a></div>
                        <div className={s.switchBox}>
                            <span className={s.text}>Meeting required</span>
                            <Form.Item
                                name="switchTeleHealthInPerson"
                                rules={[
                                    { required: true, message: 'Contact type is a required field' },
                                ]}
                                valuePropName="checked"
                            >
                                <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                />
                            </Form.Item>
                        </div>
                        {
                            showContactTypes &&
                            <Form.Item
                                name="contactType"
                                rules={[
                                    { required: true, message: 'Contact type is a required field' },
                                ]}
                            >
                                <Checkbox.Group options={CONTACT_TYPES_OPTIONS} />
                            </Form.Item>
                        }
                    </div>
                </div>
                <div className={s.box}>
                    <div className={s.title}>Duration</div>
                    <div className={s.formItem}>
                        <Form.Item
                            label="Default"
                            name="defaultDuration"
                            rules={[
                                { required: true, message: 'Default duration is a required field' },
                                {
                                    validator: (rules, value) => {
                                        const minDuration = formInstance.getFieldValue('minDuration');
                                        const maxDuration = formInstance.getFieldValue('maxDuration');

                                        if (value < minDuration) {
                                            return Promise.reject(
                                                new Error('Default Duration cannot be less than Min Duration'),
                                            );
                                        }

                                        if (value > maxDuration) {
                                            return Promise.reject(
                                                new Error('Default Duration cannot be moren than Max Duration'),
                                            );
                                        }
                                        return Promise.resolve(true);
                                    },
                                },
                            ]}
                        >
                            <InputNumber min="0" max="100000" style={{ width: '166px' }} addonAfter="Mins" onChange={() => formInstance.validateFields()} />
                        </Form.Item>
                    </div>
                    <div className={s.formItem}>
                        <Form.Item
                            label="Min"
                            name="minDuration"
                            rules={[
                                { required: true, message: 'Min duration is a required field' },
                                {
                                    validator: (rules, value) => {
                                        const defaultDuration = formInstance.getFieldValue('defaultDuration');
                                        const maxDuration = formInstance.getFieldValue('maxDuration');

                                        if (defaultDuration < value) {
                                            return Promise.reject(
                                                new Error('Min Duration cannot be more than Default Duration'),
                                            );
                                        }

                                        if (value > maxDuration) {
                                            return Promise.reject(
                                                new Error('Min Duration cannot be moren than Max Duration'),
                                            );
                                        }
                                        return Promise.resolve(true);
                                    },
                                },
                            ]}
                        >
                            <InputNumber min="0" max="100000" style={{ width: '166px' }} addonAfter="Mins" onChange={() => formInstance.validateFields()} />
                        </Form.Item>
                    </div>
                    <div className={s.formItem}>
                        <Form.Item
                            label="Max"
                            name="maxDuration"
                            rules={[
                                { required: true, message: 'Max duration is a required field' },
                                {
                                    validator: (rules, value) => {
                                        const defaultDuration = formInstance.getFieldValue('defaultDuration');
                                        const minDuration = formInstance.getFieldValue('minDuration');

                                        if (defaultDuration > value) {
                                            return Promise.reject(
                                                new Error('Default Duration cannot be more than Max Duration'),
                                            );
                                        }

                                        if (minDuration > value) {
                                            return Promise.reject(
                                                new Error('Max Duration cannot be less than Min Duration'),
                                            );
                                        }
                                        return Promise.resolve(true);
                                    },
                                },
                            ]}
                        >
                            <InputNumber min="0" max="100000" style={{ width: '166px' }} addonAfter="Mins" onChange={() => formInstance.validateFields()} />
                        </Form.Item>
                    </div>
                </div>
                <div className={s.box}>
                    <div className={s.title}>Patient accepted and limitations</div>
                    <div className={s.formItem}>
                        <div className={s.switchBox}>
                            <span className={s.text}>Need to setup patient group and limitations</span>
                            <Form.Item
                                name="switchPatientGroupLimitation"
                                rules={[
                                    { required: true, message: 'Contact type is a required field' },
                                ]}
                                valuePropName="checked"
                            >
                                <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                />
                            </Form.Item>
                        </div>
                    </div>
                    {
                        showPatientGroupNLimitation &&
                        (
                            <>
                                <div className={s.formItem}>
                                    <Form.Item
                                        label="Patient accepted"
                                        style={{ width: '510px' }}
                                        name="klarityServiceTypePatientGroupList"
                                    >
                                        <DragabelList contextType="patientGroup" placeholder="Input a new patient group" />
                                    </Form.Item>
                                </div>
                                <div className={s.formItem}>
                                    <Form.Item
                                        label="Limitations"
                                        style={{ width: '510px' }}
                                        name="klarityServiceTypeLimitationList"
                                    >
                                        <DragabelList contextType="limitation" placeholder="Input a new limitation" />
                                    </Form.Item>
                                </div>
                            </>
                        )
                    }
                </div>
            </Form>
        </div>
    );
};

export default EditForm;
