import React from 'react';
import s from './s.module.less';
import type { TResult, TPreviewResult } from 'types/operation';
import { Button } from 'antd';
import cx from 'classnames';

type IProps = {
    isMobileMode: boolean;
    previewItem: TPreviewResult;
};

const RESULT_HASH = {
    1: 'Low',
    2: 'Moderate',
    3: 'High',
};

const PreviewResult: React.FC<IProps> = (props) => {
    const { isMobileMode, previewItem } = props;
    if (!previewItem.title) {
        return (
            <div className={isMobileMode ? s.previewMobile : s.previewPc} />
        );
    }
    return (
        <div className={isMobileMode ? s.previewMobile : s.previewPc}>
            <div
                className={cx(
                    s.wrap,
                    s[`result${RESULT_HASH[previewItem.riskLevel || 1]}`],
                )}
            >
                <div className={s.content}>
                    <div className={s.result}>
                        <div className={s.left}>
                            <h3 className={cx(s.resultTitle, s.pc)}>{previewItem.title}</h3>
                            <div className={s.bar}>
                                <span>Low</span>
                                <span>Moderate</span>
                                <span>High</span>
                            </div>
                            <p className={s.resultText}>
                                Your ADHD indication level is
                                {` ${RESULT_HASH[previewItem.riskLevel || 1].toLowerCase()}`}
                            </p>
                            <p className={s.detail}>{previewItem.description}</p>
                            <div className={s.btnWrap}>
                                <Button
                                    type="primary"
                                    shape="round"
                                    className={s.primaryButton}
                                >
                                    {previewItem.primaryCta === 'Collect email'
                                        ? 'Send results'
                                        : previewItem.primaryCta}
                                </Button>
                                {previewItem.secondaryCta && (
                                    <Button shape="round" className={s.secondaryButton}>
                                        {previewItem.secondaryCta === 'Collect email'
                                            ? 'Get the full report'
                                            : previewItem.primaryCta}
                                    </Button>
                                )}
                            </div>
                        </div>
                        <div className={s.right}>
                            <h3 className={cx(s.resultTitle, s.mobile)}>
                                You’re doing great
                            </h3>
                            <div className={s.imgWrap}>
                                {previewItem.imgUrl && (
                                    <img
                                        src={previewItem.imgUrl}
                                        alt="article"
                                        style={{ objectFit: 'cover' }}
                                        className={s.imgDisplay}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PreviewResult;
