import React, { useMemo } from 'react';
import s from './s.module.less';
import { Tabs } from 'antd';
import ServicesTemplatePage from 'pages/operation/ServicesTemplatePage';
import KlarityServices from './components/KlarityServices';
import AccessStore from 'store/Access/permission';
import { PermissionModuleKeys } from 'constants/access';
import AccessDenied from 'components/AccessDenied';

const ServiceTemplateManagement = () => {
    const [getAccessStore] = AccessStore.useStore();
    const access = getAccessStore('data');
    const items = useMemo(() => {
        return [
            {
                key: '1',
                label: 'General service templates',
                children: access?.[PermissionModuleKeys.GENERAL_SERVICE] ? <ServicesTemplatePage /> : <AccessDenied />,
            },
            {
                key: '2',
                label: 'Klarity services',
                children: access?.[PermissionModuleKeys.KLARITY_SERVICE] ? <KlarityServices /> : <AccessDenied />,
            },
        ];
    }, [access]);
    return (
        <div className={s.wrap}>
            <div className={s.title}>Service template management</div>
            <Tabs
                items={items}
            />
        </div>
    );
};

export default ServiceTemplateManagement;
