import SubscriptionStore from 'store/Subscription';
import { EStripeStatus, TSubscriptionItemFromServer, TSubscriptionType } from 'types/subscription';
import { Provider, PartOfNameEnmu, UniprofileFormData, HomeInfo, CouponType } from 'types/provider';
import { klarityPlanLevel } from 'types/channel';
import { ESource } from 'types/operation';

export const checkHasSubmit = (profileStatus?: string) => {
    const hasSubmit = profileStatus && !(['required', 'update-in-progress'].includes(profileStatus));

    return hasSubmit;
};

export const showNewFlag = (value: boolean | null) => {
    if (value === true || value === null) {
        return true;
    }

    return false;
};

export const isFreeUserByInfo = (payPlan?: TSubscriptionItemFromServer) => {
    if (!payPlan || !payPlan.type) {
        return true;
    }
    if (!payPlan.originStatus) {
        return payPlan.type === TSubscriptionType.FREE;
    }
    if ([TSubscriptionType.KLARITY, TSubscriptionType.KLARITY_STARTER, TSubscriptionType.KLARITY_PLUS, TSubscriptionType.KLARITY_PRO].includes(payPlan.type)) {
        return false;
    }
    if (payPlan?.type === TSubscriptionType.FREE) {
        return true;
    }
    return (![EStripeStatus.ACTIVE, EStripeStatus.TRIALING].includes(payPlan.originStatus!));
};

export const isPaidFailUserByInfo = (payPlan?: TSubscriptionItemFromServer) => {
    return payPlan && payPlan.priceId && ([EStripeStatus.PAST_DUE, EStripeStatus.UNPAID, EStripeStatus.PAUSED].includes(payPlan.originStatus!));
};

export const isPaidFailUser = () => {
    const payPlan = SubscriptionStore.get('currentPlan');
    return isPaidFailUserByInfo(payPlan);
};

export const getPlanTypeByInfo = (payPlan?: TSubscriptionItemFromServer): TSubscriptionType => {
    //payPlan.priceId &&
    if (!payPlan?.type) {
        return TSubscriptionType.FREE;
    }
    if (payPlan && payPlan.originStatus && [EStripeStatus.ACTIVE, EStripeStatus.TRIALING].includes(payPlan.originStatus)) {
        return payPlan.type;
    }
    //play fail for klarity plan
    if ([TSubscriptionType.KLARITY, TSubscriptionType.KLARITY_PLUS, TSubscriptionType.KLARITY_PRO, TSubscriptionType.KLARITY_STARTER].includes(payPlan?.type!)) {
        return TSubscriptionType.KLARITY;
    }
    return TSubscriptionType.FREE;
};

export const getProviderNameFromEmail = (email: string) => {
    let name = email;
    try {
        const matchResult = email.match(/^(.+)@/);

        name = matchResult ? matchResult[1] : email;
    } catch (e) {
        console.error(e);
    }

    return name;
};

export const getProviderNameDisplay = (providerData: Partial<Provider> = {}): string => {
    if (!providerData) {
        return '';
    }
    const { firstName, lastName, middleName, email = '' } = providerData;

    const arr = [firstName, middleName, lastName].filter(Boolean);
    const isEmpty = arr.length === 0;
    return isEmpty ? getProviderNameFromEmail(email) : arr.join(' ').trim();
};

export const hasEffectiveMic = (providerData?: UniprofileFormData): boolean => {
    if (!providerData) {
        return false;
    }
    if (providerData.source !== 'E') {
        return true;
    }
    const { malpracticeInsuranceCertificate } = providerData;
    if (!malpracticeInsuranceCertificate) {
        return false;
    }
    const expTime = (malpracticeInsuranceCertificate.expireDate?.unix() || 0) * 1000;
    const nowTime = Date.now();
    return nowTime < expTime;
};

export const shouldShowKlarityPlanUI = (homeInfo?: HomeInfo): boolean => {
    return !!(homeInfo && homeInfo.klarityUserWithoutKiwiPlan);
};

export const getProviderKlarityPlanLevel = (homeInfo?: HomeInfo): number => {
    if (!homeInfo) {
        return -1;
    }
    if (homeInfo && homeInfo.klarityUser && homeInfo.klarityUserWithoutKiwiPlan && !klarityPlanLevel.includes(homeInfo.providerPlan?.type)) {
        return 1;
    }
    const index = klarityPlanLevel.findIndex((e) => e === homeInfo?.providerPlan?.type);

    return index > -1 ? index + 1 : -1;
};

export const isFreeUser = (homeInfo?: HomeInfo) => {
    if (shouldShowKlarityPlanUI(homeInfo)) {
        return false;
    }
    const payPlan = SubscriptionStore.get('currentPlan');

    return isFreeUserByInfo(payPlan);
};

export const isKlarityUserOnly = (homeInfo?: HomeInfo) => {
    return homeInfo && homeInfo.klarityUser && homeInfo.klarityUserWithoutKiwiPlan
};

export const isKlarityUserWithKiwiPlanPlan = (homeInfo?: HomeInfo) => {
    return homeInfo && homeInfo.klarityUser && !homeInfo.klarityUserWithoutKiwiPlan
};

export const isInHeadwayDiscountCycle = () => {
    const payPlan = SubscriptionStore.get('currentPlan');
    return payPlan?.couponType === CouponType.HEADWAY;
};

export const isSignFromHeadway = (homeInfo?: HomeInfo) => {
    return homeInfo && homeInfo.signUpFrom && homeInfo.signUpFrom.toLowerCase() === ESource.HEADWAY?.toLowerCase();
};